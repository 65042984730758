import React from "react"
import BasicLayout from "src/components/layout/BasicLayout/BasicLayout"
import SEO from "src/components/seo"
import DevelopmentPage from "src/components/DevelopmentPage/DevelopmentPage"

const WebDevelopment = () => (
  <BasicLayout stickyBtn={false}>
    <SEO title="Web Development" />
    <DevelopmentPage
      pageTitle="Web Development"
      imagePath="web-development-intro.png"
    />
  </BasicLayout>
)

export default WebDevelopment
