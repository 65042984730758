import React from "react"
import classes from "./OurExpertise.module.scss"

const OurExpertise = ({ title, list }) => {
  return (
    <div className={`twoColumnBlock ${classes.expWrapper}`}>
      <div className="leftColumn">
        <h3 className={classes.title}>{title}</h3>
      </div>

      <div className="rightColumn">
        <div className={classes.expContentWrapper}>
          {list.map(item => (
            <div className={classes.expContentItem} key={item.id}>
              <h4 className={classes.itemTitle}>{item.title}</h4>
              <p className={classes.itemText}>{item.text}</p>
              <ul className={classes.itemTechnologiesList}>
                {item.techList.map((techItem, index) => (
                  <li className={classes.itemTechnologiesItem} key={index}>
                    {techItem}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default OurExpertise
