import React, { useState } from "react"
import PropTypes from "prop-types"
import classes from "./SolutionBlock.module.scss"

const SolutionBlock = ({ solutionText }) => {
  const [isOpenText, setOpenText] = useState(false)
  const indexFirstParagraphEnd = solutionText.text.indexOf("\n")
  const firstParagraph = solutionText.text.slice(0, indexFirstParagraphEnd)
  const otherText = solutionText.text
    .slice(indexFirstParagraphEnd)
    .split("\n")
    .filter(item => !!item)

  return (
    <div className={`${classes.solutionWrapper} twoColumnBlock`}>
      <div className="leftColumn">
        <h3 className={classes.title}>{solutionText.title}</h3>
      </div>

      <div className="rightColumn">
        <div className={classes.textWrapper}>
          <p className={classes.text}>{firstParagraph}</p>
          <div
            style={
              isOpenText
                ? {
                    opacity: 1,
                    color: "inherit",
                    maxHeight: "500px",
                  }
                : {
                    opacity: 0,
                    color: "transparent",
                    maxHeight: "0",
                    overflow: "hidden",
                  }
            }
          >
            {otherText.map((text, index) => (
              <p key={index} className={classes.text}>
                {text}
              </p>
            ))}
          </div>
          <button
            className={classes.more}
            onClick={() => setOpenText(!isOpenText)}
          >
            {!isOpenText ? "See more" : "Close"}
          </button>
        </div>
      </div>
    </div>
  )
}

SolutionBlock.propTypes = {
  solutionText: PropTypes.object.isRequired,
}

export default SolutionBlock
