import React from "react"
import classes from "./PageNameAndImage.module.scss"
import PropTypes from "prop-types"
import Image from "../../image"
import SvgIcon from "../../SvgIcon"
import circles from "src/assets/icons/twoCircles.svg"
import squares from "src/assets/icons/twoSquares.svg"
import rectangles from "src/assets/icons/twoRightRectangles.svg"

const PageNameAndImage = ({ name, imagePath, isShowIcon, features }) => (
  <div className={classes.wrapper}>
    <h1 className={classes.title}> {name} </h1>
    <Image
      src={imagePath}
      className={
        isShowIcon
          ? `${classes.image} ${classes["image__withIcons"]}`
          : classes.image
      }
    />
    {isShowIcon && (
      <div className={classes.iconsBlockWrapper}>
        <div className={classes.iconBlock}>
          <SvgIcon icon={circles} className={classes.icon} />
          <span className={classes.iconSubscription}> {features[0]} </span>
        </div>
        <div className={classes.iconBlock}>
          <SvgIcon icon={squares} className={classes.icon} />
          <span className={classes.iconSubscription}> {features[1]} </span>
        </div>
        <div className={classes.iconBlock}>
          <SvgIcon icon={rectangles} className={classes.icon} />
          <span className={classes.iconSubscription}> {features[2]}</span>
        </div>
      </div>
    )}
  </div>
)

PageNameAndImage.propTypes = {
  name: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  isShowIcon: PropTypes.bool,
  features: PropTypes.arrayOf(PropTypes.string),
}

PageNameAndImage.defaultProps = {
  isShowIcon: true,
  features: [],
}

export default PageNameAndImage
